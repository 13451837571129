import { BackendErrorCodeMapValue } from './BackendErrorCodeList'
import { WsChangeMessageModel } from './wsChangeModels'
import { WsCreateDataModel } from './wsCreateDataModels'

export type WsCreateMessageModel<Data = WsCreateDataModel> = {
  [WsOperationType.Create]: {
    data: Data
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsDeleteMessageModel = {
  [WsOperationType.Delete]: {
    id: string
    model: WsMessageModel
  }
  tenantId?: number
  useInternalErrorHandling?: boolean
}

export type WsRestoreMessageTenantModel = {
  [WsOperationType.Restore]: {
    id: string
    model: WsMessageModel
  }
  tenantId?: number
  useInternalErrorHandling?: boolean
}

export type WsDeleteTenantMessageModel = WsDeleteMessageModel & {
  [WsOperationType.Delete]: WsDeleteMessageModel['delete'] & {
    data: {
      permanent: boolean
    }
  }
}

export type WsDeleteCategoryMessageModel = WsDeleteMessageModel & {
  [WsOperationType.Delete]: WsDeleteMessageModel['delete'] & {
    id: string
  }
}

export type WsMoveFolderMessageDataModel = {
  sourceFolderId: number
  targetAtlasId: number
}

export type WsMoveJourneyMessageDataModel = {
  sourceJourneyId: number
  targetAtlasId?: number
  targetFolderId?: number
}

export type WsMoveMessageModel = {
  [WsOperationType.Move]: {
    data: WsMoveFolderMessageDataModel | WsMoveJourneyMessageDataModel
    id: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsRestoreMessageModel = {
  recovery: {
    data: {
      sourceJourneyId: number
      targetAtlasId?: number
      targetFolderId?: number
    }
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsRestoreTenantMessageModel = {
  restore: {
    id?: string
    model: WsMessageModel
  }
  tenantId?: number
  useInternalErrorHandling?: boolean
}

// If a request doesn't require tenantId, then set the prop tenantId to 0
export type WsSubscribeMessageModel = {
  [WsOperationType.Subscribe]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsUnsubscribeMessageModel = {
  tenantId: number
  [WsOperationType.Unsubscribe]: {
    id?: string
    model: WsMessageModel
  }
  useInternalErrorHandling?: boolean
}

export type WsGetFoldersMessageModel = {
  [WsOperationType.Folders]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsSearchMessageModel = {
  [WsOperationType.Search]?: {
    data?: string
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsVersionListMessageModel = {
  [WsOperationType.VersionList]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsGetUserStoryBindingsModel = {
  [WsOperationType.UserStoryBindings]: {
    id: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

/**
 * param data.id - journey ID
 */
export type WsDuplicateDocumentMessage = {
  [WsOperationType.DuplicateDocument]: {
    data: {
      name: string
      targetAtlasId: number
      targetFolderId?: number
      targetTenantId: number
    }
    id: string
    model: WsMessageModel.Document
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsRevertMessage = {
  [WsOperationType.Revert]: { data?: number; id?: string; model: WsMessageModel }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsFoldersMessage = {
  [WsOperationType.Folders]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsVersioningMessage = {
  tenantId: number
  [WsOperationType.Version]: { data?: number; id?: string; model: WsMessageModel }
  useInternalErrorHandling?: boolean
}

export type WsVersionListBackMessage = {
  [WsOperationType.Back]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsVersionListForwardMessage = {
  [WsOperationType.Forward]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsIsAtlasEmptyMessage = {
  [WsOperationType.IsAtlasEmpty]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsIsFolderEmptyMessage = {
  [WsOperationType.IsFolderEmpty]: {
    id?: string
    model: WsMessageModel
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsLogoutMessage = {
  [WsOperationType.Logout]: {
    id: string
  }
  tenantId: number
  useInternalErrorHandling?: boolean
}

export type WsRequestMessage = {
  msgid?: string
  useInternalErrorHandling?: boolean
} & (
  | WsChangeMessageModel
  | WsCreateMessageModel
  | WsDeleteMessageModel
  | WsMoveMessageModel
  | WsRestoreMessageModel
  | WsRestoreTenantMessageModel
  | WsSubscribeMessageModel
  | WsUnsubscribeMessageModel
  | WsGetFoldersMessageModel
  | WsSearchMessageModel
  | WsVersionListMessageModel
  | WsGetUserStoryBindingsModel
  | WsDuplicateDocumentMessage
  | WsRevertMessage
  | WsVersioningMessage
  | WsFoldersMessage
  | WsVersionListBackMessage
  | WsVersionListForwardMessage
  | WsIsAtlasEmptyMessage
  | WsIsFolderEmptyMessage
  | WsLogoutMessage
)

export type WsErrorMessage = {
  error: {
    code: string
    message?: string
    params: any
    stackTrace?: string
  }
  msgid: string
}

export type WsEnhancedErrorMessage = Omit<WsErrorMessage, 'error'> & {
  error: WsErrorMessage['error'] & BackendErrorCodeMapValue
}

export type WsResponseMessageOkContent = {
  backenable?: boolean
  data?: any
  forwardenable?: boolean
  id?: string
  model?: WsMessageModel
  userId?: number
  version?: number
}

type WsResponseMessageBase = {
  error?: {
    code: string
    params?: Array<any>
  }
  messageType: WsMessageType
  msgid: string
  operationType?: WsOperationType
  sideloads?: Array<any>
  tenantId?: string | number
}

export type WsResponseMessage = Partial<Record<WsOperationType, WsResponseMessageOkContent>> &
  WsResponseMessageBase

export type WsEnhancedResponseMessage<ResponseData = any> = {
  backenable?: WsResponseMessageOkContent['backenable']
  data?: ResponseData
  empty?: boolean
  forwardenable?: WsResponseMessageOkContent['forwardenable']
  id?: string
  model?: WsResponseMessageOkContent['model']
  operationType: WsOperationType
  sideloads?: WsResponseMessageBase['sideloads']
  tenantId: number
  userId?: WsResponseMessageOkContent['userId']
  version?: WsResponseMessageOkContent['version']
}

export enum WsOperationType {
  Back = 'back',
  Change = 'change',
  Create = 'create',
  Delete = 'delete',
  DuplicateDocument = 'duplicate',
  Folders = 'getfolders',
  Forward = 'forward',
  Initialize = 'initialize',
  IsAtlasEmpty = 'isatlasempty',
  IsFolderEmpty = 'isfolderempty',
  Logout = 'logout',
  Move = 'move',
  Recovery = 'recovery',
  Restore = 'restore',
  Revert = 'revert',
  Search = 'search',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  UserStoryBindings = 'getuserstorybindings',
  Version = 'version',
  VersionList = 'versionlist',
}

export enum WsMessageModel {
  ArchivedTenants = 'archivedTenants',
  Atlas = 'atlas',
  AtlasGroup = 'atlasgroup',
  AtlasGroupManagement = 'groupmanagement',
  Capability = 'capability',
  CapabilityList = 'capabilityList',
  CapabilitySectorList = 'capabilitySectorList',
  Category = 'category',
  CategoryList = 'categoryList',
  Channel = 'channel',
  ChannelList = 'channelList',
  ChannelVisibility = 'channelVisibility',
  Configuration = 'configuration',
  ContentEmpty = 'contentempty',
  Document = 'document',
  DocumentCache = 'documentCache',
  DocumentList = 'documentList',
  Folder = 'folder',
  GlobalTemplateDocument = 'globalTemplateDocument',
  GlobalTemplateDocumentList = 'globalTemplateDocumentList',
  Group = 'group',
  KpmgUser = 'kpmguser',
  KpmgUserTenants = 'kpmgusertenants',
  LocalTemplateDocument = 'localTemplateDocument',
  LocalTemplateDocumentList = 'localTemplateDocumentList',
  Persona = 'persona',
  PersonaDocuments = 'personaDocuments',
  Phase = 'phase',
  RecycleBin = 'recyclebin',
  ResendTicketSystem = 'resendticketsystem',
  Search = 'search',
  SubCapability = 'subcapability',
  SubCapabilityPublic = 'subcapabilitypublic',
  Subphase = 'subphase',
  TemplateDocumentList = 'templateDocumentList',
  Tenant = 'tenant',
  TenantChannels = 'tenantchannels',
  TenantDashboard = 'dashboard',
  TenantList = 'tenantlist',
  TenantPersonas = 'tenantpersonas',
  Touchpoint = 'touchpoint',
  User = 'user',
  UserGroups = 'usergroups',
  UserPreference = 'userpreference',
  UserStory = 'userstory',
  UserStoryBindings = 'userstorybindings',
  UserStoryList = 'userstorylist',
}

export enum WsMessageType {
  Notification = 'notification',
  Response = 'response',
  Unknown = 'unknown',
}

export enum PersonaModel {
  PersonaItem = 'persona-item',
}

export type WsSubscribeDataSpec = Partial<{
  [K in WsOperationType]: unknown
}>

export type WsSubscribeCallbacks<Data extends WsSubscribeDataSpec> = Partial<{
  [K in keyof Data]: (
    data: WsEnhancedResponseMessage<Data[K]> | undefined,
    err?: WsEnhancedErrorMessage,
  ) => void
}>

export type WsEmptyContentData = {
  empty: boolean
  id: number
}

export enum WsCacheEnum {
  No = 'no',
  Short = 'short',
  Yes = 'yes',
}
