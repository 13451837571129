import { ReactNode, Suspense } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import {
  Login,
  TestPage,
  Backlog,
  Tutorial,
  Personas,
  PersonaDetail,
  LandingPage,
  Folder,
  Error,
  Journey,
  TenantLimits,
  AtlasDetail,
  NewJourney,
  Atlases,
  ChannelManagement,
  ManagementOfTenant,
  Search,
  ConnectedCapabilities,
  UserManagement,
  ListOfJourneys,
  TemplateManagement,
  ArchivedTenants,
  ArchivedJourneys,
} from '@dis/screens'
import AtlasIcon from '@dis/assets/src/icons/AtlasIcon.svg'
import BacklogIcon from '@dis/assets/src/icons/BacklogIcon.svg'
import JourneyIcon from '@dis/assets/src/icons/JourneyIcon.svg'
import TenantIcon from '@dis/assets/src/icons/TenantIcon.svg'
import ListIcon from '@dis/assets/src/icons/ListIcon.svg'
import UserIcon from '@dis/assets/src/icons/UserIcon.svg'
import IntroductionIcon from '@dis/assets/src/icons/IntroductionIcon.svg'
import HomeIcon from '@dis/assets/src/icons/HomeIcon.svg'
import SettingsIcon from '@dis/assets/src/icons/SettingsIcon.svg'
import QuestionmarkIcon from '@dis/assets/src/icons/QuestionmarkIcon.svg'
import BinIcon from '@dis/assets/src/icons/BinIcon.svg'
import FolderIcon from '@dis/assets/src/icons/FolderIcon.svg'
import ConnectedCapabilitiesIcon from '@dis/assets/src/icons/ConnectedCapabilitiesIcon.svg'
import SearchIcon from '@dis/assets/src/icons/SearchIcon.svg'
import { ComponentsLoader, SvgImage } from '@dis/components'
import { colors } from '@dis/styles'
import { Tenants } from '@dis/screens/src/Tenants/Tenants'
import NavigationIcon from '@dis/assets/src/icons/NavigationIcon.svg'
import { TutorialIntroduction } from '@dis/screens/src/Tutorial/Introduction/TutorialIntroduction'
import { TutorialSettings } from '@dis/screens/src/Tutorial/Settings/TutorialSettings'
import { TutorialExportImport } from '@dis/screens/src/Tutorial/ExportImport/TutorialExportImport'
import { TutorialPersonas } from '@dis/screens/src/Tutorial/Personas/TutorialPersonas'
import PersonasIcon from '@dis/assets/src/icons/PersonasIcon.svg'
import ExportIcon from '@dis/assets/src/icons/ExportIcon.svg'
import { TutorialNavigation } from '@dis/screens/src/Tutorial/Navigation/TutorialNavigation'
import { TutorialJourneys } from '@dis/screens/src/Tutorial/Journey/TutorialJourneys'
import { generateRouteObjects, setRoutes } from './utils'
import { RouteHandleCrumbContent } from './types'
import { styles } from './styles'

const lazy = (element: ReactNode) => <Suspense fallback={<ComponentsLoader />}>{element}</Suspense>

export const routeObjects = generateRouteObjects()

const atlasesRoute = (
  <Route
    path={routeObjects.atlases.path}
    handle={{
      crumb: (): RouteHandleCrumbContent => ({
        icon: <SvgImage src={ListIcon} color={colors.gray.gray60} className={styles.routeIcon} />,
        routeToKey: 'atlases',
      }),
      name: routeObjects.atlases.name,
    }}>
    <Route index element={lazy(<Atlases />)} />

    <Route
      path={routeObjects.atlas.path}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: (
            <SvgImage src={AtlasIcon} color={colors.gray.gray60} className={styles.routeIcon} />
          ),
          routeToKey: 'atlas',
        }),
        name: routeObjects.atlas.name,
      }}>
      <Route index element={lazy(<AtlasDetail />)} />

      <Route path={routeObjects.newJourney.path}>
        <Route
          index
          element={lazy(<NewJourney />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: (
                <SvgImage
                  src={JourneyIcon}
                  color={colors.gray.gray60}
                  className={styles.routeIcon}
                />
              ),
              routeToKey: 'newJourneyAtlas',
            }),
            name: routeObjects.newJourneyAtlas.name,
          }}
        />
      </Route>

      <Route path={routeObjects.folders.path}>
        <Route index element={<Navigate to="/" />} />
        <Route
          path={routeObjects.folder.path}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={FolderIcon} />,
              routeToKey: 'folder',
            }),
            name: routeObjects.folder.name,
          }}>
          <Route index element={lazy(<Folder />)} />

          <Route path={routeObjects.journeys.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.journeyFolder.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: (
                    <SvgImage
                      src={JourneyIcon}
                      color={colors.gray.gray60}
                      className={styles.routeIcon}
                    />
                  ),
                  routeToKey: 'journeyFolder',
                }),
                name: routeObjects.journeyFolder.name,
              }}
            />
          </Route>

          <Route path={routeObjects.newJourney.path}>
            <Route
              index
              element={lazy(<NewJourney />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: (
                    <SvgImage
                      src={JourneyIcon}
                      color={colors.gray.gray60}
                      className={styles.routeIcon}
                    />
                  ),
                  routeToKey: 'newJourneyFolder',
                }),
                name: routeObjects.newJourneyFolder.name,
              }}
            />
          </Route>
        </Route>
      </Route>

      <Route path={routeObjects.journeys.path}>
        <Route index element={<Navigate to="/" />} />
        <Route
          path={routeObjects.journey.path}
          element={lazy(<Journey />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: (
                <SvgImage
                  src={JourneyIcon}
                  color={colors.gray.gray60}
                  className={styles.routeIcon}
                />
              ),
              routeToKey: 'journey',
            }),
            name: routeObjects.journey.name,
          }}
        />
      </Route>
    </Route>
  </Route>
)

const tutorialRoute = (
  <Route
    path={routeObjects.tutorial.path}
    handle={{
      crumb: (): RouteHandleCrumbContent => ({
        icon: <SvgImage src={QuestionmarkIcon} />,
        routeToKey: 'tutorial',
      }),
      name: routeObjects.tutorial.name,
    }}>
    <Route index element={lazy(<Tutorial />)} />

    <Route
      path={routeObjects.tutorialIntroduction.path}
      element={lazy(<TutorialIntroduction />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={IntroductionIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialIntroduction',
        }),
        name: routeObjects.tutorialIntroduction.name,
      }}
    />

    <Route
      path={routeObjects.tutorialNavigation.path}
      element={lazy(<TutorialNavigation />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={NavigationIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialNavigation',
        }),
        name: routeObjects.tutorialNavigation.name,
      }}
    />

    <Route
      path={routeObjects.tutorialSettings.path}
      element={lazy(<TutorialSettings />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={SettingsIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialSettings',
        }),
        name: routeObjects.tutorialSettings.name,
      }}
    />

    <Route
      path={routeObjects.tutorialExportImport.path}
      element={lazy(<TutorialExportImport />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={ExportIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialExportImport',
        }),
        name: routeObjects.tutorialExportImport.name,
      }}
    />

    <Route
      path={routeObjects.tutorialPersonas.path}
      element={lazy(<TutorialPersonas />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={PersonasIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialPersonas',
        }),
        name: routeObjects.tutorialPersonas.name,
      }}
    />

    <Route
      path={routeObjects.tutorialJourneys.path}
      element={lazy(<TutorialJourneys />)}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={JourneyIcon} className={styles.routeIcon} />,
          routeToKey: 'tutorialJourneys',
        }),
        name: routeObjects.tutorialJourneys.name,
      }}
    />
  </Route>
)

export const getRouter = (rootElement: ReactNode) => {
  const routes = createRoutesFromElements(
    <Route
      element={rootElement}
      errorElement={<Error />}
      handle={{
        crumb: (): RouteHandleCrumbContent => ({
          icon: <SvgImage src={HomeIcon} />,
          routeToKey: 'tenants',
        }),
        name: routeObjects.tenants.name,
      }}>
      <Route index element={<Tenants />} />

      <Route
        path={routeObjects.login.path}
        element={<Login />}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'login' }),
          name: routeObjects.login.name,
        }}
      />

      <Route
        path={routeObjects.test.path}
        element={lazy(<TestPage />)}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'test' }),
          name: routeObjects.test.name,
        }}
      />

      <Route
        path={routeObjects.archivedTenants.path}
        element={lazy(<ArchivedTenants />)}
        handle={{
          crumb: (): RouteHandleCrumbContent => ({
            icon: <SvgImage src={BinIcon} />,
            routeToKey: 'archivedTenants',
          }),
          name: routeObjects.archivedTenants.name,
        }}
      />

      <Route path={routeObjects.tenants.path} errorElement={<Error />}>
        <Route index element={<Navigate to="/" />} />

        <Route
          path={routeObjects.tenant.path}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              alternativeIcon: <SvgImage src={HomeIcon} />,
              icon: <SvgImage src={TenantIcon} />,
              routeToKey: 'tenant',
            }),
            name: routeObjects.tenant.name,
          }}>
          <Route index element={lazy(<LandingPage />)} />

          <Route
            path={routeObjects.capabilities.path}
            element={lazy(<ConnectedCapabilities />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={ConnectedCapabilitiesIcon} />,
                routeToKey: 'capabilities',
              }),
              name: routeObjects.capabilities.name,
            }}
          />

          {atlasesRoute}

          <Route
            path={routeObjects.personas.path}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={ListIcon} />,
                routeToKey: 'personas',
              }),
              name: routeObjects.personas.name,
            }}>
            <Route index element={lazy(<Personas />)} />
            <Route
              path={routeObjects.persona.path}
              element={lazy(<PersonaDetail />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={UserIcon} />,
                  routeToKey: 'persona',
                }),
                name: routeObjects.persona.name,
              }}
            />
          </Route>

          <Route
            path={routeObjects.backlog.path}
            element={lazy(<h2>backlog</h2>)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={BacklogIcon} />,
                routeToKey: 'backlog',
              }),
              name: routeObjects.backlog.name,
            }}
          />

          <Route
            path={routeObjects.search.path}
            element={<Search />}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={SearchIcon} />,
                routeToKey: 'search',
              }),
              name: routeObjects.search.name,
            }}
          />

          <Route path={routeObjects.globalTemplatePreview.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.globalTemplate.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'globalTemplate' }),
                name: routeObjects.globalTemplate.name,
              }}
            />
          </Route>

          <Route path={routeObjects.localTemplatePreview.path}>
            <Route index element={<Navigate to="/" />} />
            <Route
              path={routeObjects.localTemplate.path}
              element={lazy(<Journey />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({ routeToKey: 'localTemplate' }),
                name: routeObjects.localTemplate.name,
              }}
            />
          </Route>

          <Route
            path={routeObjects.userStory.path}
            element={lazy(<Backlog />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: <SvgImage src={BacklogIcon} />,
                routeToKey: 'userStory',
              }),
              name: routeObjects.userStory.name,
            }}
          />

          <Route
            path={routeObjects.listOfJourneys.path}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: (
                  <SvgImage
                    src={ListIcon}
                    color={colors.gray.gray60}
                    className={styles.routeIcon}
                  />
                ),
                routeToKey: 'listOfJourneys',
              }),
              name: routeObjects.listOfJourneys.name,
            }}>
            <Route index element={lazy(<ListOfJourneys />)} />

            {atlasesRoute}

            <Route
              path={routeObjects.archivedJourneys.path}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={BinIcon} />,
                  routeToKey: 'archivedJourneys',
                }),
                name: routeObjects.archivedJourneys.name,
              }}>
              <Route index element={lazy(<ArchivedJourneys />)} />

              <Route
                path={routeObjects.recycleBinJourney.path}
                element={lazy(<Journey />)}
                handle={{
                  crumb: (): RouteHandleCrumbContent => ({
                    icon: (
                      <SvgImage
                        src={JourneyIcon}
                        color={colors.gray.gray60}
                        className={styles.routeIcon}
                      />
                    ),
                    routeToKey: 'recycleBinJourney',
                  }),
                  name: routeObjects.recycleBinJourney.name,
                }}
              />
            </Route>
          </Route>

          <Route
            path={routeObjects.newJourney.path}
            element={lazy(<NewJourney />)}
            handle={{
              crumb: (): RouteHandleCrumbContent => ({
                icon: (
                  <SvgImage
                    src={JourneyIcon}
                    color={colors.gray.gray60}
                    className={styles.routeIcon}
                  />
                ),
                routeToKey: 'newJourney',
              }),
              name: routeObjects.newJourney.name,
            }}
          />

          <Route path={routeObjects.administration.path}>
            <Route
              index
              element={<Navigate to="/" />}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  routeToKey: 'administration',
                }),
                name: routeObjects.administration.name,
              }}
            />
            <Route
              path={routeObjects.limits.path}
              element={lazy(<TenantLimits />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'limits',
                }),
                name: routeObjects.limits.name,
              }}
            />

            <Route
              path={routeObjects.channels.path}
              element={lazy(<ChannelManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'channels',
                }),
                name: routeObjects.channels.name,
              }}
            />
            <Route
              path={routeObjects.userManagement.path}
              element={lazy(<UserManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'userManagement',
                }),
                name: routeObjects.userManagement.name,
              }}
            />
            <Route
              path={routeObjects.capabilityManagement.path}
              element={lazy(<ConnectedCapabilities />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'capabilityManagement',
                }),
                name: routeObjects.capabilityManagement.name,
              }}
            />

            <Route
              path={routeObjects.templateManagement.path}
              element={lazy(<TemplateManagement />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'templateManagement',
                }),
                name: routeObjects.templateManagement.name,
              }}
            />

            <Route
              path={routeObjects.editTenant.path}
              element={lazy(<ManagementOfTenant />)}
              handle={{
                crumb: (): RouteHandleCrumbContent => ({
                  icon: <SvgImage src={SettingsIcon} />,
                  routeToKey: 'editTenant',
                }),
                name: routeObjects.editTenant.name,
              }}
            />
          </Route>

          {tutorialRoute}

          <Route index element={<Navigate to="/" />} />
        </Route>
      </Route>

      <Route path={routeObjects.globalAdministration.path}>
        <Route
          index
          element={<Navigate to="/" />}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={SettingsIcon} />,
              routeToKey: 'globalAdministration',
            }),
            name: routeObjects.globalAdministration.name,
          }}
        />

        <Route
          path={routeObjects.globalUserManagement.path}
          element={lazy(<UserManagement />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={SettingsIcon} />,
              routeToKey: 'globalUserManagement',
            }),
            name: routeObjects.globalUserManagement.name,
          }}
        />

        <Route
          path={routeObjects.globalTemplateManagement.path}
          element={lazy(<TemplateManagement />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={SettingsIcon} />,
              routeToKey: 'globalTemplateManagement',
            }),
            name: routeObjects.globalTemplateManagement.name,
          }}
        />

        <Route
          path={routeObjects.globalCapabilityManagement.path}
          element={lazy(<ConnectedCapabilities />)}
          handle={{
            crumb: (): RouteHandleCrumbContent => ({
              icon: <SvgImage src={SettingsIcon} />,
              routeToKey: 'globalCapabilityManagement',
            }),
            name: routeObjects.globalCapabilityManagement.name,
          }}
        />
      </Route>

      {tutorialRoute}
    </Route>,
  )

  setRoutes(routes)

  return createBrowserRouter(routes)
}
