import SearchIcon from '@dis/assets/src/icons/SearchIcon.svg'
import SettingsIcon from '@dis/assets/src/icons/SettingsIcon.svg'
import AtlasIcon from '@dis/assets/src/icons/AtlasIcon.svg'
import SentIcon from '@dis/assets/src/icons/SentIcon.svg'
import QuestionmarkIcon from '@dis/assets/src/icons/QuestionmarkIcon.svg'
import LogoutIcon from '@dis/assets/src/icons/LogoutIcon.svg'
import { getFlagIcon, changeLanguageList, LANGUAGES } from '@dis/constants'
import { tKeys } from '@dis/languages'
import { RouteNames } from '@dis/routing'
import ExportPDF from '@dis/assets/src/icons/PdfIcon.svg'
import { SubmenuDataItem } from './types'

export type MenuDataItem = {
  icon: string
  items?: Array<SubmenuDataItem>
  navigateTo?: RouteNames
  onClick?: VoidFunction
  requireTenant: boolean
  title: string
  type: keyof typeof applicationMenuItemTypes
}

export const applicationMenuItemTypes = {
  configuration: {
    capabilities: 'capabilities',
    capabilityManagement: 'capabilityManagement',
    channels: 'channels',
    editTenant: 'editTenant',
    globalCapabilityManagement: 'globalCapabilityManagement',
    globalTemplateManagement: 'globalTemplateManagement',
    globalUserManagement: 'globalUserManagement',
    limits: 'limits',
    templateManagement: 'templateManagement',
    userManagement: 'userManagement',
  },
  exportToPdf: 'exportToPdf',
  language: LANGUAGES,
  logout: 'logout',
  logoutAllDevices: 'logoutAllDevices',
  search: 'search',
  sendEmail: 'sendEmail',
  tutorial: 'tutorial',
} as const

/**
 * Function approach to always return brand new data
 */
export const getMenuData = (): MenuDataItem[] => [
  {
    icon: SearchIcon,
    navigateTo: 'search',
    requireTenant: true,
    title: tKeys.applicationMenu.searching,
    type: 'search',
  },
  {
    icon: ExportPDF,
    requireTenant: true,
    title: tKeys.exportImport.exportToPdf,
    type: 'exportToPdf',
  },
  {
    icon: AtlasIcon,
    items: changeLanguageList.map((item) => ({
      icon: getFlagIcon({ langCode: item.value }),
      requireTenant: false,
      title: item.label,
      type: item.value,
      value: item.value,
    })),
    requireTenant: false,
    title: tKeys.applicationMenu.chooseLanguage,
    type: 'language',
  },
  {
    icon: SettingsIcon,
    items: [
      {
        navigateTo: 'editTenant',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.manageTenant,
        type: 'editTenant',
      },
      {
        navigateTo: 'limits',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.tenantLimits,
        type: 'limits',
      },
      {
        navigateTo: 'userManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.userManagement,
        type: 'userManagement',
      },
      {
        navigateTo: 'globalUserManagement',
        requireTenant: false,
        title: tKeys.applicationMenu.subMenu.userManagement,
        type: 'globalUserManagement',
      },
      {
        navigateTo: 'channels',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.manageChannels,
        type: 'channels',
      },
      {
        navigateTo: 'templateManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.templateManagement,
        type: 'templateManagement',
      },
      {
        navigateTo: 'globalTemplateManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.templateManagement,
        type: 'globalTemplateManagement',
      },
      {
        navigateTo: 'capabilities',
        requireTenant: true,
        title: tKeys.applicationMenu.connectedCapabilities,
        type: 'capabilities',
      },
      {
        navigateTo: 'capabilityManagement',
        requireTenant: true,
        title: tKeys.applicationMenu.subMenu.capabilityManagement,
        type: 'capabilityManagement',
      },
      {
        navigateTo: 'globalCapabilityManagement',
        requireTenant: false,
        title: tKeys.applicationMenu.subMenu.capabilityManagement,
        type: 'globalCapabilityManagement',
      },
    ] as SubmenuDataItem<keyof ApplicationMenuItemTypes['configuration']>[],
    requireTenant: false,
    title: tKeys.applicationMenu.configuration,
    type: 'configuration',
  },
  {
    icon: SentIcon,
    requireTenant: false,
    title: tKeys.applicationMenu.sendFeedback,
    type: 'sendEmail',
  },
  {
    icon: QuestionmarkIcon,
    navigateTo: 'tutorial',
    requireTenant: false,
    title: tKeys.applicationMenu.tutorials,
    type: 'tutorial',
  },
  {
    icon: LogoutIcon,
    requireTenant: false,
    title: tKeys.applicationMenu.logOutThisDevice,
    type: 'logout',
  },
  {
    icon: LogoutIcon,
    requireTenant: false,
    title: tKeys.applicationMenu.logOutAllDevices,
    type: 'logoutAllDevices',
  },
]

export type ApplicationMenuItemTypes = typeof applicationMenuItemTypes
